import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/manage/staff/order/list`,
    method: 'POST',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/api/manage/staff/order/del?id=${id}`,
    method: 'post',
    data: {}
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/api/manage/staff/order/detail`,
    method: 'post',
    params: { id }
  })
}